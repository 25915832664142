import React from 'react'
import Layout from "../components/App/Layout"
import PageBannerBackground from "../components/Common/PageBannerBackground"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'

const Contact = ({location}) => {

    return (
        <Layout page="Contact Us">
            <PageBannerBackground
                pageTitle="CONTACT US"
                crumbs={[{page: "Home", url: "/"}]}   
                bgFileName="contact/contact-banner.jpg"
            />
            <ContactInfo />
            <ContactForm location={location} />
            
        </Layout>
    );
}

export default Contact